.hero_slider,
.hero {
  @apply relative;

  &__image,
  &__images {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%);
  }

  // image when only 1 image is used
  &__image {
    min-height: 500px;
    @screen mdmax {
      min-height: 300px;
    }

    width: 100%;
  }

  // images when swiper
  &__images {
    & .swiper-button-prev {
      margin-left: 5%;
    }

    & .swiper-slide > div {
      min-height: 500px;
      @screen mdmax {
        min-height: 300px;
      }
    }

    + .hero_slider__content {
      z-index: 1;
    }
  }

  &__content {
    min-height: 500px;
    @screen mdmax {
      min-height: 300px;
    }

    pointer-events: none;
    @apply flex items-center relative z-10;

    &__wrapper {
      & h1 {
        @apply text-shadow-white;
      }

      & a,
      & button {
        pointer-events: all;
      }
    }
  }
}
