body#tinymce {
  margin: 12px !important;
}

.internal-link {
  &::after {
    @include fa_light("\f0c1");
  }

  & svg {
    @apply ml-1;
  }
}

.external-link {
  &::after {
    @include fa_light("\f08e");
  }

  & svg {
    @apply ml-1;
  }
}

a,
button {
  @apply transition-all duration-300 ease-in-out;
}

::selection {
  @apply bg-black text-secondary;
}

#app {
  overflow: hidden;
}

button:focus {
  outline: none;
}

.grecaptcha-badge {
  display: none !important;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: calc(0.7rem + 0.5vw);
  @screen mdmax {
    font-size: calc(0.8rem + 0.4vw);
  }
}

body {
  @apply bg-white transition prose ease-in-out duration-500;
}

main {
  overflow: hidden; // for header and simple hero
}
