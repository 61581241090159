.footer {
  & #menu-footer-menu {
    max-height: 300px;
  }

  & a {
    @apply text-gray-400 hover:text-secondary font-normal;

    &.nav-link {
      @apply no-underline;
    }
  }
}
