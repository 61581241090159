.prev-next-post {
  z-index: 1;
  position: fixed;
  top: 40%;
  width: 100%;
  left: 0;
  display: flex;
  pointer-events: none;

  & .prev-post,
  & .next-post {
    @apply transform transition-all flex items-center text-sm pointer-events-auto cursor-pointer;

    &__hover {
      @apply bg-black;

      max-width: 150px;

      & p {
        @apply my-0 p-1;
      }

      & a {
        @apply no-underline text-white hover:text-primary;
      }
    }

    &__arrow {
      @apply text-white bg-primary p-1 shadow-sm flex flex-col items-center;

      min-width: 50px;
      @screen mdmax {
        min-width: 40px;
      }
    }
  }

  & .prev-post {
    transform: translateX(calc(-100% + 50px));
    @screen mdmax {
      transform: translateX(calc(-100% + 40px));
    }

    &:hover {
      transform: translateX(0);
    }
  }

  & .next-post {
    transform: translateX(calc(100% - 50px));
    @screen mdmax {
      transform: translateX(calc(100% - 40px));
    }

    &:hover {
      transform: translateX(0);
    }
  }
}
