.grid {
  @apply w-full; // forces full width even with small content
  &.style_dark {
    & > * {
      @apply relative;

      & img {
        @apply w-full;
      }

      & .grid-item__content {
        // @ apply absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2;
        @apply absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center text-center p-3 bg-black bg-opacity-50 hover:bg-secondary hover:bg-opacity-75  transition ease-in-out duration-500;

        & .wr_heading,
        & .wr_text {
          @apply text-white;
        }

        & a,
        & button {
          @apply text-white hover:text-black;
        }
      }
    }
  }
}
