@mixin circ_icon_style($color: $link-color, $hover: $link-color-hover) {
  &:not(:last-of-type) {
    margin-right: 0.25em;
  }

  border: 2px solid $color;
  color: $color;
  border-radius: 100em;
  box-shadow: $box-shadow;
  padding: 0.65em;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: $hover;
    border-color: $hover;
  }
}

/*
  * Font Awesome
  * ref - https://fontawesome.com/how-to-use/on-the-web/advanced/css-pseudo-elements
  */
@mixin fa_solid($icon) {
  content: "#{$icon}";
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
}

@mixin fa_regular($icon) {
  content: "#{$icon}";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
}

@mixin fa_light($icon) {
  content: "#{$icon}";
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  display: none;
}

@mixin fa_brands($icon) {
  content: "#{$icon}";
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

@mixin fa_duotone($icon1, $icon2) {
  &::before {
    content: "#{$icon1}";
  }

  &::after {
    content: "#{$icon2}";
  }

  &::before,
  &::after {
    font-family: "Font Awesome 5 Duotone";
    font-weight: 900;
    display: none;
  }
}
