$toggleArrowSpacer: 0.5em;

.dropdown {
  position: relative;

  & .dropdown-toggle {
    z-index: 1;
    display: flex !important;
    align-items: center;
    @apply relative;

    &::after {
      content: "";
      display: inline-block;
      width: $toggleArrowSpacer;
      height: $toggleArrowSpacer;
      margin-bottom: calc(#{$toggleArrowSpacer} / 2);
      cursor: pointer;
      border-left: 2px solid;
      border-bottom: 2px solid;
      transform: rotate(-45deg) // translate(
        //   calc(#{$toggleArrowSpacer} / 4),
        //   calc(calc(-#{$toggleArrowSpacer} / 4))
        // )
;
      @apply ml-2;
    }

    &.active {
      @apply bg-primary;
      //   &::after {
      //     transform: rotate(135deg) !important;
      //   }
    }
  }

  & .dropdown-menu {
    @apply bg-white my-0 opacity-0 invisible absolute pointer-events-none z-10 overflow-y-scroll w-full;

    @screen md {
      min-width: 400px;
    }
    @screen mdmax {
      max-width: calc(100% - calc(theme("spacing.3") * 2));
      left: theme("spacing.3");
    }

    &.active {
      @apply pointer-events-auto;
    }

    & li {
      @apply border-b border-gray-100;
    }
  }
}
