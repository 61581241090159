@layer base {
  input,
  textarea,
  select {
    @apply transform transition-colors duration-300 ease-in-out;
  }

  /**
   * Base styles
   * Needed to be set on the [type] to override tw forms
   */
  [type="text"],
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple], // file
  textarea,
  select {
    @apply mt-0 mb-3 block w-full px-1 border-0 border-b-2 border-primary focus:ring-0 focus:border-secondary focus:border-b-4;
  }

  ::placeholder {
    @apply text-gray-400 #{!important};
  }

  select {
    @apply border-2;
  }

  form button[type="submit"] {
    @apply mt-3;
  }
}

/**
 * Validation stuff
 */
.valid-feedback,
.invalid-feedback {
  @apply hidden pb-1 text-sm mb-2 w-full;
}

.is-invalid ~ .invalid-feedback,
.is-valid ~ .valid-feedback {
  @apply block;
}

.is-valid {
  @apply border-success #{!important} focus:ring-4 focus:ring-success focus:ring-opacity-25;

  & ~ .valid-feedback {
    @apply text-success;
  }
}

.is-invalid {
  @apply border-danger #{!important} focus:ring-4 focus:ring-danger focus:ring-opacity-25;

  & ~ .invalid-feedback {
    @apply text-danger;
  }
}

/**
 * Search form
 */
#search {
  @apply relative;

  & #search-toggle {
    @apply rounded-full bg-gray-100 hover:bg-secondary-light hover:text-white transition-colors duration-300 ease-in-out cursor-pointer w-5 h-5 flex items-center justify-center;

    position: relative;
  }

  & #search-wrapper {
    @apply bg-gray-100 absolute right-full -top-1/2 p-3 opacity-0 invisible pointer-events-none z-10;

    &.active {
      @apply pointer-events-auto;

      & + #search-toggle {
        @apply bg-secondary-light hover:text-white;
      }
    }

    & #search-form {
      & .screen-reader-text {
        @apply hidden;
      }

      & input {
        min-width: 400px;
        @screen mdmax {
          min-width: 60vw;
        }
      }
    }

    & #search-results {
      & ul {
        @apply mb-0;

        & li {
          @apply pb-2 mb-2 border-b last:border-b-0;

          & a {
            @apply text-sm;
          }
        }
      }
    }
  }
}
