.container-padding {
  @apply container py-6;
}

.wr_section {
  @apply py-6;

  @screen mdmax {
    text-align: left !important;
  }

  & .wr_text {
    & > ul {
      @apply mt-0;
    }

    & p:first-of-type {
      @apply mt-0;
    }

    // & p:last-of-type:not(:first-of-type) {
    //   @apply mb-0;
    // }
  }

  & .wr_heading,
  & .wr_sub-heading,
  & .wr_text,
  & .wr_btns {
    + .wr_text,
    + .wr_btns {
      @apply mt-3;
    }
  }

  & .wr_btns {
    @apply flex flex-wrap mdmax:flex-col;
  }

  &.text-center {
    & .wr_btns {
      @apply justify-center;
    }
  }

  &.text-right {
    & .wr_btns {
      @apply justify-end;
    }
  }

  & .wr_heading + .wr_sub-heading {
    @apply -mt-3 #{!important};
  }
}

.has-separator-angle_to_bl {
  @apply py-10 -mt-5;

  clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%);
  @screen lgmax {
    clip-path: polygon(0 5%, 100% 0, 100% 95%, 0 100%);
  }
}

.has-separator-angle_to_br {
  @apply py-10 -mt-5;

  clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 90%);
  @screen lgmax {
    clip-path: polygon(0 0, 100% 5%, 100% 100%, 0 95%);
  }
}
