.accordion {
  @apply mb-3;

  &__item {
    @apply w-full overflow-hidden border-t;

    &__heading {
      @apply font-normal pl-5 pr-8 py-3 bg-white hover:bg-primary hover:text-white cursor-pointer flex items-center relative z-10 transition ease-in-out duration-500;

      &::after {
        @apply absolute right-5;

        content: "";
        width: 1rem;
        height: 1rem;
        cursor: pointer;
        border-left: 2px solid;
        border-bottom: 2px solid;
        transform: rotate(-45deg) // translate(
          //   calc(#{$toggleArrowSpacer} / 4),
          //   calc(calc(-#{$toggleArrowSpacer} / 4))
          // )
;
      }

      .expanded & {
        @apply bg-gray-200 text-gray-600;
      }
    }

    &__content {
      @apply overflow-hidden border-l-2 bg-white border-primary px-5 h-0;

      .expanded-initially & {
        @apply h-auto;
      }
    }
  }
}
