.header {
  position: relative;
  z-index: 51; // 1 higher than swiper-scrollbar
  @apply relative bg-white;

  &.stuck {
    @apply fixed w-full top-0 shadow;

    & > div {
      @apply hidden;
    }
  }

  & .navbar {
    & .brand {
      & img {
        height: auto;
        width: calc(5vw + 100px);
      }
    }

    & .menu {
      @apply flex items-center md:ml-3;

      & > ul {
        @apply flex md:flex-wrap flex-1 mdmax:w-full items-center justify-end my-0;

        & li {
          @apply pl-0 my-0 mdmax:w-full mdmax:text-center;

          &::before {
            content: none;
          }

          & a {
            @apply px-3 py-2 hover:bg-gray-100 text-black hover:text-secondary block no-underline font-normal;
          }
        }
      }
      @screen mdmax {
        @apply transition-opacity duration-500 ease-in-out flex-col opacity-0 pointer-events-none justify-center fixed w-screen h-screen top-0 left-0 bg-black bg-opacity-90 #{!important};

        &.active {
          @apply opacity-100 pointer-events-auto z-40 #{!important};
        }

        & ul:not(.dropdown-menu) {
          @apply flex-col justify-start overflow-y-scroll overflow-x-hidden #{!important};

          & a:not(.dropdown-item) {
            @apply text-white hover:bg-transparent hover:text-secondary py-3 text-headingSm inline-block #{!important};
          }
        }
      }
    }
  }

  & .hamburger {
    @apply block relative cursor-pointer md:hidden ml-3 fill-current bg-secondary rounded p-1 hover:bg-secondary transition transition-all;

    width: 48px;
    height: 44px;

    & span {
      height: 4px;
      left: 7.5px;
      width: calc(100% - 15px);
      @apply bg-white absolute;

      transform: rotate(0deg);
      transition: 0.25s ease-in-out;

      &:nth-child(1) {
        top: 10px;
      }

      &:nth-child(2) {
        top: 20px;
      }

      &:nth-child(3) {
        top: 30px;
      }
    }

    &.active {
      @apply z-50 bg-primary;

      & span {
        &:nth-child(1) {
          top: 20px;
          transform: rotate(135deg);
        }

        &:nth-child(2) {
          opacity: 0;
          left: -30px;
        }

        &:nth-child(3) {
          top: 20px;
          transform: rotate(-135deg);
        }
      }
    }
  }
}
