/**
 * ==========================
 * Import required components in resources/assets/styles/config/external
 * Override below
 * ==========================
 *
 * Core - ~swiper/swiper.scss
 */
:root {
  --swiper-theme-color: theme("colors.primary.DEFAULT");
  @screen mdmax {
    --swiper-navigation-size: 21px;
  }
}

/**
 * Reset flex for Swiper
 */
.wr_section * {
  min-height: 0;
  min-width: 0;
}

.swiper-container {
  z-index: auto !important;
}

/**
    * Scrollbar - ~swiper/components/scrollbar/scrollbar.scss
    */
.swiper-scrollbar {
  @apply bg-secondary;

  height: 10px !important;
}

.swiper-scrollbar-drag {
  @apply bg-secondary;
}

/**
     * Pagination - ~swiper/components/pagination/pagination.scss
     */
.swiper-pagination {
  &-bullets {
    bottom: 0;

    &-dynamic {
      & .swiper-pagination-bullet-active-prev,
      & .swiper-pagination-bullet-active-next {
        transform: scale(0.75);
        opacity: 0.3;
      }
    }
  }

  &-bullet {
    width: 16px;
    height: 16px;
    @apply bg-secondary;
  }
}

/**
* Navigation - ~swiper/components/navigation/navigation.scss
     */
.swiper-button-prev,
.swiper-button-next {
  @apply font-bold text-white;

  filter: drop-shadow(0 0 7.5px theme("colors.black"));
}

/**
 * Client logos swiper
 */
.client-logo-slider {
  & .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;

    & img {
      height: 100px;
      width: auto;
      object-fit: contain;
    }
  }
}

.services-slider {
  & .swiper-slide {
    & img {
      height: 300px;
      width: 100%;
      object-fit: cover;
    }
  }
}
